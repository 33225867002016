var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exerciseList" },
    [
      _vm.showLoading
        ? _c("van-loading", {
            staticStyle: { "text-align": "center", "margin-top": "50px" },
            attrs: { size: "18", color: "#1989fa" }
          })
        : [
            _c("div", { staticClass: "main" }, [
              _c("div", { staticClass: "questions" }, [
                _c("div", { staticClass: "title" }, [
                  _vm.currentQuesrion.questionType === "SingleChoice"
                    ? _c("em", [_vm._v("单选")])
                    : _vm.currentQuesrion.questionType === "MultiChoice"
                    ? _c("em", [_vm._v("多选")])
                    : _vm.currentQuesrion.questionType === "Judge"
                    ? _c("em", [_vm._v("判断")])
                    : _vm.currentQuesrion.questionType === "FillIn"
                    ? _c("em", [_vm._v("填空")])
                    : _vm.currentQuesrion.questionType === "QuestionAndAnswer"
                    ? _c("em", [_vm._v("问答")])
                    : _vm._e(),
                  _vm.currentQuesrion.orderIndex
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.currentQuesrion.orderIndex) + "、")
                      ])
                    : _vm._e(),
                  _c("span", {
                    staticStyle: { "word-break": "break-all" },
                    domProps: {
                      innerHTML: _vm._s(_vm.currentQuesrion.questionContent)
                    }
                  }),
                  _vm.currentQuesrion.MaxScore
                    ? _c("i", [
                        _vm._v(_vm._s(_vm.currentQuesrion.MaxScore) + "分")
                      ])
                    : _vm._e()
                ]),
                _c("div", { staticClass: "answer" }, [
                  _c(
                    "ul",
                    [
                      _vm.currentQuesrion.questionType == "SingleChoice"
                        ? _vm._l(_vm.currentQuesrion.choiceItems, function(q) {
                            return _c("li", { key: q.ID }, [
                              _c("em", { class: { on: q.isAnswer == 1 } }, [
                                _vm._v(_vm._s(q.itemCode))
                              ]),
                              _c("span", {
                                domProps: { innerHTML: _vm._s(q.itemContent) }
                              })
                            ])
                          })
                        : _vm._e(),
                      _vm.currentQuesrion.questionType == "MultiChoice"
                        ? _vm._l(_vm.currentQuesrion.choiceItems, function(q) {
                            return _c("li", { key: q.ID }, [
                              _c("em", { class: { on: q.isAnswer == 1 } }, [
                                _vm._v(_vm._s(q.itemCode))
                              ]),
                              _c("span", {
                                domProps: { innerHTML: _vm._s(q.itemContent) }
                              })
                            ])
                          })
                        : _vm._e(),
                      _vm.currentQuesrion.questionType == "Judge"
                        ? _vm._l(_vm.currentQuesrion.judgeItems, function(q) {
                            return _c("li", { key: q.ID }, [
                              _c("em", { class: { on: q.isAnswer == 1 } }, [
                                _vm._v(_vm._s(q.itemCode))
                              ]),
                              _c("span", {
                                domProps: { innerHTML: _vm._s(q.itemContent) }
                              })
                            ])
                          })
                        : _vm.currentQuesrion.questionType == "FillIn"
                        ? _vm._l(_vm.currentQuesrion.fillinItems, function(q) {
                            return _c(
                              "li",
                              { key: q.ID },
                              [
                                _c("em", [_vm._v(_vm._s(q.itemCode))]),
                                _c("van-field", {
                                  attrs: { placeholder: "", readonly: "" }
                                })
                              ],
                              1
                            )
                          })
                        : _vm.currentQuesrion.questionType ==
                          "QuestionAndAnswer"
                        ? [
                            _c(
                              "li",
                              [
                                _c("van-field", {
                                  attrs: {
                                    rows: "3",
                                    autosize: "",
                                    type: "textarea",
                                    placeholder: "",
                                    readonly: ""
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]),
                _c("div", { staticClass: "mark" }, [
                  _c(
                    "em",
                    {
                      style: {
                        color: _vm.currentQuesrion.isMark
                          ? "#EE7805"
                          : "#0066B3"
                      },
                      on: { click: _vm.goMark }
                    },
                    [
                      _c("i", { staticClass: "iconfont iconbiaoji" }),
                      _vm._v(
                        _vm._s(_vm.currentQuesrion.isMark ? "取消标记" : "标记")
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "analyse" }, [
                  _c("div", [
                    _c("p", [
                      _vm._v("答案: "),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.questionAnswer(_vm.currentQuesrion)
                          )
                        }
                      })
                    ])
                  ]),
                  _c("div", [
                    _c("h5", [_c("em"), _vm._v(" 考点")]),
                    _c(
                      "p",
                      _vm._l(_vm.currentQuesrion.pointList, function(e) {
                        return _c("span", { key: e }, [_vm._v(_vm._s(e))])
                      }),
                      0
                    )
                  ]),
                  _c("div", [
                    _c("h5", [_c("em"), _vm._v("解析")]),
                    _c("p", {
                      domProps: {
                        innerHTML: _vm._s(_vm.currentQuesrion.explainText)
                      }
                    })
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "footer" }, [
              _c("div", { staticClass: "mark", on: { click: _vm.setCard } }, [
                _c("i", { staticClass: "iconfont icondatika" }),
                _vm._v(
                  " " +
                    _vm._s(_vm.currentNum + 1) +
                    "/" +
                    _vm._s(_vm.exerciseList.length) +
                    " "
                )
              ]),
              _c("div", { staticClass: "btn" }, [
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.prevDisabled },
                    on: { click: _vm.prev }
                  },
                  [_vm._v("上一题")]
                ),
                _c(
                  "button",
                  {
                    attrs: { disabled: _vm.nextDisabled },
                    on: { click: _vm.next }
                  },
                  [_vm._v("下一题")]
                )
              ])
            ]),
            _c("AnswerCard", {
              attrs: {
                questionsList: _vm.exerciseList,
                AnswersList: _vm.AnswersList
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }