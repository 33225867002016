<template>
  <div class="exerciseList">
    <van-loading
      size="18"
      color="#1989fa"
      v-if="showLoading"
      style="text-align: center;margin-top:50px;"
    />
    <template v-else>
      <!-- 考题 -->
      <div class="main">
        <div class="questions">
          <div class="title">
            <em v-if="currentQuesrion.questionType === 'SingleChoice'">单选</em>
            <em v-else-if="currentQuesrion.questionType === 'MultiChoice'">多选</em>
            <em v-else-if="currentQuesrion.questionType === 'Judge'">判断</em>
            <em v-else-if="currentQuesrion.questionType === 'FillIn'">填空</em>
            <em v-else-if="currentQuesrion.questionType === 'QuestionAndAnswer'">问答</em>
            <span v-if="currentQuesrion.orderIndex">{{ currentQuesrion.orderIndex }}、</span>
            <span v-html="currentQuesrion.questionContent" style="word-break: break-all;"></span>
            <i v-if="currentQuesrion.MaxScore">{{ currentQuesrion.MaxScore }}分</i>
          </div>
          <div class="answer">
            <ul>
              <!-- 单选-->
              <template v-if="currentQuesrion.questionType == 'SingleChoice'">
                <li v-for="q in currentQuesrion.choiceItems" :key="q.ID">
                  <em :class="{ on: q.isAnswer == 1 }">{{ q.itemCode }}</em>
                  <span v-html="q.itemContent"></span>
                </li>
              </template>
              <!-- 多选 -->
              <template v-if="currentQuesrion.questionType == 'MultiChoice'">
                <li v-for="q in currentQuesrion.choiceItems" :key="q.ID">
                  <em :class="{ on: q.isAnswer == 1 }">{{ q.itemCode }}</em>
                  <span v-html="q.itemContent"></span>
                </li>
              </template>
              <!-- 判断 -->
              <template v-if="currentQuesrion.questionType == 'Judge'">
                <li v-for="q in currentQuesrion.judgeItems" :key="q.ID">
                  <em :class="{ on: q.isAnswer == 1 }">{{ q.itemCode }}</em>
                  <span v-html="q.itemContent"></span>
                </li>
              </template>
              <!-- 填空 -->
              <template v-else-if="currentQuesrion.questionType == 'FillIn'">
                <li v-for="q in currentQuesrion.fillinItems" :key="q.ID">
                  <em>{{ q.itemCode }}</em>
                  <van-field placeholder="" readonly />
                </li>
              </template>
              <!-- 问答题 -->
              <template v-else-if="currentQuesrion.questionType == 'QuestionAndAnswer'">
                <li>
                  <van-field rows="3" autosize type="textarea" placeholder="" readonly />
                </li>
              </template>
            </ul>
          </div>
          <div class="mark">
            <em @click="goMark" :style="{ color: currentQuesrion.isMark ? '#EE7805' : '#0066B3' }">
              <i class="iconfont iconbiaoji"></i
              >{{ currentQuesrion.isMark ? "取消标记" : "标记" }}</em
            >
          </div>
          <div class="analyse">
            <div>
              <p>答案: <span v-html="questionAnswer(currentQuesrion)"> </span></p>
            </div>
            <div>
              <h5><em></em> 考点</h5>
              <p>
                <span v-for="e in currentQuesrion.pointList" :key="e">{{ e }}</span>
              </p>
            </div>
            <div>
              <h5><em></em>解析</h5>
              <p v-html="currentQuesrion.explainText"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="mark" @click="setCard">
          <i class="iconfont icondatika"></i> {{ currentNum + 1 }}/{{ exerciseList.length }}
        </div>
        <div class="btn">
          <button @click="prev" :disabled="prevDisabled">上一题</button>
          <button @click="next" :disabled="nextDisabled">下一题</button>
        </div>
      </div>
      <!-- 答题卡 -->
      <AnswerCard :questionsList="exerciseList" :AnswersList="AnswersList" />
    </template>
  </div>
</template>

<script>
import { CountDown, Field, Loading } from "vant";
import AnswerCard from "./components/AnswerCard.vue";
import { practiceExamList, answerPracticeExam } from "@/api/exam.api.js";
import { mapState, mapMutations } from "vuex";
import { debounce } from "../../utils/index.js";
import dayjs from "dayjs";
export default {
  name: "exerciseList",
  components: {
    "van-count-down": CountDown,
    "van-field": Field,
    AnswerCard,
    "van-loading": Loading
  },
  data() {
    return {
      showLoading: false,
      id: null,
      userExamId: null,
      exerciseList: [],
      data: {},
      currentQuesrion: {}, //当前题目
      prevDisabled: true,
      nextDisabled: false,
      AnswersList: []
    };
  },

  computed: {
    ...mapState(["cardShow", "questionsNum", "currentNum"])
  },
  watch: {
    //答题卡跳题
    currentNum() {
      this.currentQuesrion = this.exerciseList[this.currentNum];
      this.prevDisabled = !this.currentNum;
      this.nextDisabled = this.currentNum == this.exerciseList.length - 1 ? true : false;
    },
    //答题卡显示的时候存值
    cardShow(n, o) {
      if (n == true) {
        this.storageQuestionId();
      }
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getPracticeExamList();
    },
    ...mapMutations(["updateCard", "updateCurrentNum"]),
    //练习题库列表
    getPracticeExamList() {
      this.showLoading = true;
      practiceExamList(this.id)
        .then(res => {
          this.showLoading = false;
          // 跳到上次看到的最后的题号
          let mylastAnswerIdIndex = res.listBean.findIndex(e => {
            return e.questionId === res.lastAnswerId;
          });
          let mylastAnswerNo = res.listBean.length >= res.lastAnswerNo;
          if (res.lastAnswerId && mylastAnswerIdIndex > -1) {
            this.updateCurrentNum(res.listBean[mylastAnswerIdIndex].orderIndex - 1);
          } else if (mylastAnswerIdIndex < 0 && mylastAnswerNo && res.lastAnswerNo) {
            this.updateCurrentNum(res.lastAnswerNo - 1);
          } else {
            this.updateCurrentNum(0);
          }
          this.data = res;
          this.userExamId = res.userExamId;
          let list = JSON.stringify(res.listBean);
          this.exerciseList = JSON.parse(list);
          // 回填答题卡的状态
          this.exerciseList.find(e => {
            if (e.isDone === 1) {
              let item = {
                userExamId: this.userExamId,
                answerNo: e.orderIndex,
                questionId: e.questionId
              };
              this.AnswersList.push(item);
            }
          });
          console.log("this.AnswersList-------87", this.AnswersList);
          this.currentQuesrion = this.exerciseList[this.currentNum];
        })
        .catch(() => {});
    },
    //  答案展示
    questionAnswer(item) {
      this.currentQuesrion.pointList =
        item.pointNames && item.pointNames.length > 0
          ? item.pointNames.split(";").filter(e => {
              return e && e.trim();
            })
          : [];
      if (item.questionType === "SingleChoice" || item.questionType === "MultiChoice") {
        let answerItem =
          item.choiceItems &&
          item.choiceItems.map(e => {
            return e.isAnswer ? e.itemCode : "";
          });
        return answerItem.join(" ");
      } else if (item.questionType === "Judge") {
        let answerItem =
          item.judgeItems &&
          item.judgeItems.map(e => {
            return e.isAnswer ? e.itemCode : "";
          });
        return answerItem.join(" ");
      } else if (item.questionType === "FillIn") {
        let answerItem =
          item.fillinItems &&
          item.fillinItems.map(e => {
            return `${e.itemCode}. ${e.itemAnswer}`;
          });
        return answerItem.join(", ");
      } else if (item.questionType === "QuestionAndAnswer") {
        return item.answerContent;
      }
    },
    setCard() {
      this.updateCard(true);
    },
    //存看过的题号
    storageQuestionId() {
      let data = {
        userExamId: this.userExamId,
        answerNo: this.currentQuesrion.orderIndex,
        questionId: this.currentQuesrion.questionId
      };
      let index = this.AnswersList.findIndex(e => {
        return e.questionId === this.currentQuesrion.questionId;
      });
      answerPracticeExam(data)
        .then(() => {})
        .catch(() => {});
      if (index === -1) {
        this.AnswersList.push(data);
      }
      console.log("this.AnswersList--------99", this.AnswersList);
    },
    prevFun() {
      // 点上一题之前,存答案
      this.storageQuestionId();
      this.nextDisabled = false;
      this.updateCurrentNum(this.currentNum - 1);
      this.currentQuesrion = this.exerciseList[this.currentNum];
      if (this.currentNum === 0) {
        this.prevDisabled = true;
        // return
      }
    },
    nextFun() {
      // 点下一题之前,存答案
      this.storageQuestionId();
      this.prevDisabled = false;
      this.updateCurrentNum(this.currentNum + 1);
      this.currentQuesrion = this.exerciseList[this.currentNum];
      if (this.exerciseList.length - 1 === this.currentNum) {
        this.nextDisabled = true;
      }
    },
    //防抖处理
    prev: debounce("prevFun", 1000),
    next: debounce("nextFun", 1000),
    goMark() {
      this.$forceUpdate();
      this.exerciseList[this.currentNum]["isMark"] =
        this.exerciseList[this.currentNum] && this.exerciseList[this.currentNum].isMark
          ? false
          : true;
      this.currentQuesrion = this.exerciseList[this.currentNum];
    }
  },
  beforeDestroy() {
    this.updateCurrentNum(0);
  }
};
</script>

<style lang="less" scoped>
.exerciseList {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .main {
    flex: 1;
    width: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .van-divider {
    color: @blueTheme;
    border-color: #d8d8d8;
    margin: 46px 30px 0px 30px;
    .van-count-down {
      color: @blueTheme;
      margin-left: 20px;
      width: 120px;
    }
  }
  .questions {
    font-size: 32px;
    color: @color3;
    .title {
      margin: 0 30px;
      padding-top: 40px;
      line-height: 53px;
      letter-spacing: 2px;
      text-align: justify;
      /deep/span div {
        display: inline;
      }
      em {
        padding: 0 15px;
        height: 40px;
        background: #e6f4ff;
        border-radius: 2px 100px 100px 2px;
        font-size: 22px;
        color: @blueTheme;
        display: inline-block;
        line-height: 39px;
        text-align: center;
        margin-right: 30px;
      }
      i {
        width: 70px;
        height: 40px;
        display: inline-block;
        background: #e6f4ff;
        border: 1px solid @blueTheme;
        border-radius: 4px;
        font-size: 24px;
        color: @blueTheme;
        line-height: 40px;
        text-align: center;
        margin-left: 10px;
      }
    }
    .answer {
      margin: 0 30px;
      padding-top: 30px;
      font-size: 28px;
      color: @color3;
      li {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        span {
          line-height: 40px;
          word-break: break-all;
        }
        em {
          min-width: 54px;
          height: 54px;
          line-height: 54px;
          text-align: center;
          font-size: 28px;
          color: @color3;
          display: inline-block;
          background: #ffffff;
          border-radius: 50%;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);
          margin-right: 20px;
          &.on {
            background: #41c160;
            color: #fff;
          }
        }
        .van-cell {
          border: 1px solid #979797;
          border-radius: 4px;
          padding: 10px;
        }
      }
    }
    .mark {
      margin-top: 50px;
      font-size: 28px;
      color: @blueTheme;
      display: flex;
      padding-right: 30px;
      em {
        display: block;
        margin-left: auto;
        i {
          margin-right: 10px;
        }
      }
    }
    .analyse {
      margin-top: 50px;
      > div {
        &:nth-child(1) {
          margin: 0 30px;
          display: flex;
          justify-content: space-between;
          background: #f7f8fa;
          border-radius: 10px;
          padding: 35px 30px;
          margin-bottom: 30px;
          p {
            line-height: 50px;
            text-align: justify;
            font-size: 28px;
          }
        }
        &:nth-child(2) {
          padding: 0 30px;
          border-top: 10px solid #f7f8fa;
          border-bottom: 10px solid #f7f8fa;
          h5 {
            margin: 20px 0;
            font-size: 32px;
            color: #333;
            em {
              background: @blueTheme;
              width: 6px;
              height: 30px;
              display: inline-block;
              border-radius: 3px;
              margin-right: 23px;
            }
          }
          p {
            margin: 30px 0;
            span {
              background: #e6f4ff;
              border: 1px solid @blueTheme;
              color: @blueTheme;
              font-size: 24px;
              margin-right: 20px;
              border-radius: 5px;
              padding: 15px 20px;
              display: inline-block;
              word-break: break-all;
            }
          }
        }
        &:nth-child(3) {
          padding: 0 30px;
          margin-bottom: 50px;
          h5 {
            margin: 20px 0;
            font-size: 32px;
            color: #333;
            em {
              background: @blueTheme;
              width: 6px;
              height: 30px;
              display: inline-block;
              border-radius: 3px;
              margin-right: 23px;
            }
          }
          p {
            word-break: break-all;
            font-size: 28px;
            color: @color3;
            line-height: 40px;
          }
        }
      }
    }
    .van-field {
      border: 1px solid #979797;
      border-radius: 4px;
    }
  }
  .footer {
    border-top: 1px solid #d8d8d8;
    padding: 20px 30px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mark {
      font-size: 28px;
      color: @color6;
      margin-top: 20px;
      i {
        color: @blueTheme;
        font-size: 30px;
      }
    }
    .btn {
      display: inline-block;
      vertical-align: baseline;
      button {
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        // color: @color3;
        border: none;
        outline: none;
        border-radius: 30px;
        background: #fff;
        display: inline-block;
        margin-left: 20px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1);
        padding: 0 40px;
        color: @blueTheme;
        &:active {
          color: #fff;
          background: @blueTheme;
        }
        &:disabled {
          color: #999999;
        }
      }
    }
  }
}
</style>
